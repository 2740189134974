import { createAction, props } from '@ngrx/store';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';

export const setAuthState = createAction(
  '[App] Set Auth State',
  props<{ authState: AuthState }>()
);

export const setCognitoUser = createAction(
  '[App] Set Cognito User',
  props<{ cognitoUser: CognitoUserInterface }>()
);
