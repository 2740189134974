import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './app.actions';
import { AppState } from './app.state';

export const initialState: AppState = {
  authState: null,
  cognitoUser: null
};

const appReducer = createReducer(
  initialState,
  on(AppActions.setAuthState, (state, { authState }) => {
    return {
      ...state,
      authState
    };
  }),
  on(AppActions.setCognitoUser, (state, { cognitoUser }) => {
    return {
      ...state,
      cognitoUser
    };
  })
);

export function reducer(state: AppState | undefined, action: Action): AppState {
  return appReducer(state, action);
}
