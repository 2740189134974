import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const idToken = (this.authService.getCognitoUser() as any).signInUserSession.idToken.jwtToken;

    console.log('idToken', idToken);

    const cloned = request.clone({
      setHeaders: {
        'Authorization': idToken
      }
    });

    return next.handle(cloned);
  }
}
