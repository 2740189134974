import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AppState, selectIsLoggedIn } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { CognitoUserInterface } from '@aws-amplify/ui-components';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private onDestroyed$ = new Subject();
  private loggedIn: boolean;
  private user: CognitoUserInterface;

  constructor(private store: Store<{ app: AppState }>) {
    this.subscribeToUser();
    this.subscribeToLoginState();
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public getCognitoUser(): CognitoUserInterface {
    return this.user;
  }

  public navigateToHostedLogin(): void {
    const loginDomain = `https://taskmonster.auth.us-east-2.amazoncognito.com`;
    const clientId = `17dhn51pcbqhe9cec63uibgoep`;
    const scopeList = [ 'aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile' ];

    // 'code' for Authorization code grant
    // 'token' for Implicit grant
    const type = `code`;

    const url = `${loginDomain}/login?client_id=${clientId}&response_type=${type}&scope=${scopeList.join('+')}&redirect_uri=${window.location.href}callback`;

    window.location.href = url;
  }

  public setAccessCode(token: string): void {
    localStorage.taskmonster_access_code = token;
  }

  public getAccessCode(): boolean {
    return localStorage.taskmonster_access_code;
  }

  subscribeToUser(): void {
    this.store.select(store => store.app.cognitoUser)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe(user => this.user = user);
  }

  subscribeToLoginState(): void {
    this.store.select(selectIsLoggedIn)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe(isl => this.loggedIn = isl);
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }
}
