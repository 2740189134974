import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState, selectIsLoggedIn } from '@app/store/app.state';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { setCognitoUser } from '@app/store/app.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  onDestroyed$ = new Subject();
  isLoggedIn = false;
  cognitoUser: CognitoUserInterface;

  constructor(
    private store: Store<{ app: AppState }>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscribeToUser();
    this.subscribeToLoginState();
  }

  async signOut(): Promise<void> {
    try {
      await Auth.signOut();
      this.router.navigateByUrl('/');
      this.store.dispatch(setCognitoUser(null));
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  subscribeToUser(): void {
    this.store.select(store => store.app.cognitoUser)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe(user => this.cognitoUser = user);
  }

  subscribeToLoginState(): void {
    this.store.select(selectIsLoggedIn)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe(isl => this.isLoggedIn = isl);
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }

}
