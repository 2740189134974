import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from '@aws-amplify/auth';
import { APP_BASE_HREF } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { HomeComponent } from '@app/home/home.component';
import { CallbackComponent } from '@shared/auth/callback/callback.component';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '@material/material.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { reducer } from '@app/store/app.reducer';
import { HttpClientModule } from '@angular/common/http';

// import awsconfig from '../aws-exports';
// /* Configure Amplify resources */
// Amplify.configure(awsconfig);

Amplify.configure({
  Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_05Z1ewQgK',
      userPoolWebClientId: '2mg0uicavdd36paq1r2egasnof',
      mandatorySignIn: true,
      oauth: {
          domain: 'https://taskmonster.auth.us-east-2.amazoncognito.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'http://localhost:4200/callback',
          redirectSignOut: 'http://localhost:4200/',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
  }
});

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    StoreModule.forRoot({ app: reducer }),
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
