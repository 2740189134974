import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    // TODO: probably need to make another request to get token before
    // redirecting
    const accessCode = this.activatedRoute.snapshot.paramMap.get('code');
    this.authService.setAccessCode(accessCode);
    this.router.navigate(['dashboard']);
  }

}
