import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from '@shared/header/header.component';
import { MaterialModule } from '@material/material.module';
import { AuthInterceptor } from '@shared/auth/interceptors/auth.interceptor';
import { AuthService } from '@shared/auth/services/auth.service';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true } // multi to allow multiple interceptors
  ],
  exports: [
    HeaderComponent
  ]
})
export class SharedModule { }
