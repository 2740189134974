<div class="home-container">
  <app-header></app-header>

  <div class="banner-container flex-row flex-fill flex-align-center">
    <amplify-authenticator *ngIf="!isLoggedIn" class="ml-auto authenticator">
      <amplify-sign-in slot="sign-in">
        <div slot="federated-buttons"></div>
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</div>
