import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Auth } from '@aws-amplify/auth';
import { setAuthState, setCognitoUser } from 'src/app/store/app.actions';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private store: Store<{ app: AppState }>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return Auth.currentAuthenticatedUser()
      .then(user => {
        const authState = AuthState.SignedIn;
        const stateUser = {cognitoUser: JSON.parse(JSON.stringify(user)) as CognitoUserInterface};
        this.store.dispatch(setCognitoUser(stateUser));
        this.store.dispatch(setAuthState({ authState }));
        return true;
      })
      .catch(err => {
        this.router.navigate(['/']);
        return false;
      });

    // if (this.authService.isLoggedIn()) {
    //   return true;
    // }

    // this.router.navigate(['/']);

    // return false;
  }

}
