import { Component, OnDestroy, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Auth } from '@aws-amplify/auth';
import {
  onAuthUIStateChange,
  CognitoUserInterface,
  AuthState
} from '@aws-amplify/ui-components';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { setAuthState, setCognitoUser } from '@app/store/app.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isProd = environment.production;

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store<{ app: AppState }>,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    onAuthUIStateChange((authState, authData) => {
      this.ngZone.run(() => {
        this.store.dispatch(setAuthState({ authState }));

        Auth.currentUserPoolUser()
          .then((user) => {
            const stateUser = {cognitoUser: JSON.parse(JSON.stringify(user)) as CognitoUserInterface};
            this.store.dispatch(setCognitoUser(stateUser));
            this.router.navigateByUrl('/dashboard');
          })
          .catch(error => {
            this.store.dispatch(setCognitoUser(null));
            this.router.navigateByUrl('/');
          });
      });
    });
  }

  ngOnDestroy(): void {}
}
