<div class="header-container flex-row flex-align-center flex-fill">
  <div routerLink="/" class="header-title hover-cursor">TASKMONSTER</div>

  <div *ngIf="isLoggedIn" class="flex-row flex-align-center ml-auto">
    <div class="greetings">Hello, {{cognitoUser?.username}}</div>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User Menu Dropdown">
      <img src="/assets/alien.png" alt="avatar image" class="avatar-image">
    </button>
  
    <div class="avatar-caret hover-cursor" [matMenuTriggerFor]="menu"></div>
  
    <mat-menu #menu="matMenu">
      <button routerLink="/dashboard" mat-menu-item>
        <mat-icon>view_quilt</mat-icon>
        <span>Dashboard</span>
      </button>
      <button mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>Profile</span>
      </button>
      <button mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </button>
      <button (click)="signOut()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>Sign Out</span>
      </button>
    </mat-menu>
  </div>
</div>
