import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { createSelector } from '@ngrx/store';

export interface AppState {
  authState: AuthState;
  cognitoUser: CognitoUserInterface;
}

export const selectApp = (state) => state.app;

export const selectIsLoggedIn = createSelector(
  selectApp,
  (state: AppState) => {
    const { authState, cognitoUser } = state;
    return authState === AuthState.SignedIn && !!cognitoUser;
  }
);
