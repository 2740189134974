import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectIsLoggedIn } from '@app/store/app.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  onDestroyed$ = new Subject();
  isLoggedIn = false;

  constructor(private ref: ChangeDetectorRef, private store: Store<{ app: AppState }>) { }

  ngOnInit(): void {
    this.subscribeToLoginState();
  }

  subscribeToLoginState(): void {
    this.store.select(selectIsLoggedIn)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((isLoggedIn) => this.setLoginState(isLoggedIn));
  }

  setLoginState(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn;
    this.ref.detectChanges();

    if (!this.isLoggedIn) {
      this.setWidgetStyling();
    }
  }

  setWidgetStyling(): void {
    const host = document.querySelector('.authenticator');

    if (host && host.shadowRoot) {
      const style = document.createElement('style');
      style.innerHTML = '.auth-container { height: auto !important; min-height: auto !important; font-weight: bold; }';
      host.shadowRoot.appendChild(style);
    }
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }

}
