import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from '@shared/auth/callback/callback.component';
import { AuthGuard } from '@shared/auth/guards/auth.guard';
import { HomeComponent } from '@app/home/home.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'dashboard',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  { path: 'callback', component: CallbackComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
